import React from "react";
import styles from './UnderMaintenance.module.scss';
import { Container } from "postcss";
import logo from '../media/logo/logo.svg';
import { FaExternalLinkAlt } from "react-icons/fa";

const UnderMaintenance: React.FC = () => {

    return (
        <div className={styles.container}>

            <div className={styles.content}>

                <div className={styles.logoTextBox}>
                    <div className={styles.logo}>
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className={styles.textBox}>
                        <p className={styles.text}>FairCloud {<span className='font-bold'>AI</span>}</p>
                    </div>
                </div>

                <div className={styles.infoBox}>

                    <div className={styles.descriptionBox}>
                        <div className={styles.title}>We Are Upgrading<br /> More <span className={styles.gradientText}>Savings</span> Incoming</div>
                    </div>

                    <div className={styles.verticalDivider}></div>

                    <div className={styles.horizontalDivider}></div>

                    <div
                        className={styles.descriptionBox}
                        onClick={() => window.location.href = 'https://careers.faircloud.ai'}
                        style={{ cursor: 'pointer' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={styles.title}>
                                <p style={{margin:0}}>
                                    We Are Actively <span className={styles.gradientText}> Hiring</span>
                                </p>
                            </div>
                            <FaExternalLinkAlt color="white" size={'1.5rem'} style={{ marginLeft: '1rem' }} />
                        </div>
                    </div>


                </div>



            </div>




        </div>
    )
}

export default UnderMaintenance;
